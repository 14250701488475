const speakerData = [
    {
        id : "a0",
        title : "Imelda Usoro Olaoye",
        role:"",
        position : "Founder, Business Women Hub",
        company : "Managing Partner, Thinkmint Nigeria/Europe",
        imageUrl : "imeldaolaoye.webp"
    },
    {
        id : "a01",
        title : "Hansatu Adegbite",
        role: "Guest Speaker",
        position : "Voice of Diverse-City / Co-Founder",
        company : "HDB Advisors",
        imageUrl : "hansatuadegbite.png"
    },
    {
        id : "a02",
        title : "Nnena Jacob-Ogogo",
        role: "Keynote Speaker",
        position : "Group Head and Head of the Gender Desk",
        company : "First City Monument Bank (FCMB)",
        imageUrl : "nnenajacob.png"
    },
    {
        id : "a1",
        title : "Mrs Kikelomo Atanda-Owo",
        role:"",
        position : "CEO",
        company : "Z-edge Consulting",
        imageUrl : "kikelomoatanda.png"
    },
    {
        id : "a111",
        title : "Abimbola Balogun",
        role:"",
        position : "Co-founder/Chief People Officer",
        company : "So Fresh",
        imageUrl : "abimbolabalogun.png"
    },
    {
        id : "a11",
        title : "Abiola Adelana",
        role:"",
        position : "Head Tourism Business",
        company : "Sterling Bank plc",
        imageUrl : "abiolaadelana.png"
    },
    {
        id : "a112",
        title : "Dr Ella Chioma Ezeadilieje",
        role:"",
        position : "Story teller / Brand Influencer",
        company : "@veronicasdaughter",
        imageUrl : "ellachioma.png"
    },
    {
        id : "a12",
        title : "Emmanuella Akinrinde",
        role:"",
        position : "Digital Creator",
        company : "",
        imageUrl : "emmanuellaakinrinde.png"
    },
    {
        id : "a2",
        title : "Lord Gusi Tobby Holloway",
        role:"",
        position : "Executive Assistant Coach,",
        company : "ED School of Service",
        imageUrl : "lordgusi.png"
    },
    {
        id : "a21",
        title : "Dr. Morenike Basharu",
        role:"",
        position : "CEO, Dewia Options Interior / Vice President",
        company : "Business School Netherlands Toast Master Club",
        imageUrl : "morenikebasharu.png"
    },
    {
        id : "a22",
        title : "Dr. Kelechi Okoro",
        role:"",
        position : "@healthertainer",
        company : "",
        imageUrl : "kelechiokoro.png"
    },
    {
        id : "a23",
        title : "Rita Ahunanya",
        role:"",
        position : "Founder",
        company : "Crestructure Consulting",
        imageUrl : "ritaahunanya.png"
    },
    {
        id : "a24",
        title : "Barr. Martha Rotoye,",
        role:"",
        position : "Head of Governance",
        company : "Risk and Compliance Services Pentangon Partners Legal Practitioners",
        imageUrl : "martharotoye.png"
    },
    {
        id : "a3",
        title : "Itee Akrasi",
        role:"",
        position : "CEO",
        company : "Teerasi Empire",
        imageUrl : "iteeakrasi.png"
    },
    {
        id : "a4",
        title : "Ufoma Okerentugba-Thompson",
        role:"",
        position : "Senior Consultant",
        company : "Centafold Consult",
        imageUrl : "ufomathompson.png"
    },
    {
        id : "a5",
        title : "Chidinma Amadi",
        role:"",
        position : "CEO",
        company : "Pama Beauty Hair",
        imageUrl : "chidinmaamadi.png"
    },
    {
        id : "a6",
        title : "Linda Ochugbua",
        role:"",
        position : "Digital Sales Manager",
        company : "",
        imageUrl : "lindaochugbua.png"
    },
 
    {
        id : "a7",
        title : "Esther Iyire",
        role:"",
        position : "CEO",
        company : "Hazel Grace Style",
        imageUrl : "estheriyire.png"
    },

    {
        id : "a8",
        title : "Precious Adesina-Ola",
        role:"",
        position : "Head of Growth",
        company : "Suplias Technologies",
        imageUrl : "preciousadesina.png"
    },
 
 
]


export default speakerData;